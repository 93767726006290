function formatText(el) {
    var select_id = $(el.element).parent().attr('id');
    if (!el.id) {
        return el.text;
    }
    else {

        return $(
            '<span><span class="name">' + el.text + '</span><span class="bigger">' +
            (( $('#'+select_id+' option[value="' + el.id + '"]').attr('data-bigger') && ($('#'+select_id+' option[value="' + el.id + '"]').attr('data-bigger') != '0'))
                ? '  (+ ' + $('#'+select_id+' option[value="' + el.id + '"]').data('currency') + $('#'+select_id+' option[value="' + el.id + '"]').data('bigger') + ')'
                : '') + '</span></span>'
        );
    }
}

function formatSelection(el) {
    var select_name = $(el.element).parent().data('placeholder');
    return select_name + ' - ' + (el.full_name || el.text)  ;
}

function sumDataBigger() {
    var sum = 0,
        sum_select = 0,
        sum_radio = 0;

    if($('.form-group.select .select2').length > 0) {

        $('.form-group.select .select2').each(function (item, el) {

            var selected = $(el).find('option:selected') && parseFloat($(el).find('option:selected').data('bigger'));

            selected ? sum += selected : sum;

        });
    }
    if($('.form-group.radio').length > 0) {

        $('.form-group.radio').each(function (item, el) {

            var selected = $(el).find('input:checked') && parseFloat($(el).find('input:checked').data('cost'));

            selected ? sum += selected : sum;

        });
    }

    return sum;
}

function changePrice(changes) {

        var change_on = changes,
            quantity = $('.form-group.buy_block select.quantity').find('option:selected').val(),

            new_product_price = $('.product_price .price'),
            float_new_price = parseFloat($(new_product_price).find('.float').attr('price')),
            new_price = quantity*(float_new_price + change_on),
            new_price_path = (((new_price%1).toFixed(2)).split("."))[1];


        $(new_product_price).find('.integer').text(new_price - new_price%1 + '.');
        $(new_product_price).find('sup').text(new_price%1 > 0 ? new_price_path : '_');

        if( $('.product_price .old_price').length > 0 ){

            var product_price = $('.product_price .old_price'),
                float_price = parseFloat($(product_price).find('.float').attr('price')),
                price = quantity*(float_price + change_on),
                price_path = (((price%1).toFixed(2)).split("."))[1];

            $(product_price).find('.integer').text(price - price%1 + '.');
            $(product_price).find('sup').text(price%1 > 0 ? price_path : '_');
        }


}

function prodListImg() {
    $('.product_list').find('.product_item').each(function (item, el) {
        var img = $(el).find('.prod_image'),
            desc = $(el).find('.prod_desc'),
            width_g = 1,
            width_v = 1,
            ratio_wh = 1,
            new_w = 0,
            width_c = $('#products .container-fluid').length > 0 ? $('#products .container-fluid').width() : $('#products .container').width();

        if ($(window).width() > 768) {
            width_g = 0.48;
            width_v = 0.35;
            ratio_wh = img.width() / img.height();


            if (img.width() >= img.height()) {
                new_w = width_c * width_g;
            }
            else {
                new_w = width_c * width_v;
            }

            $(img).width(new_w).find('img').width(new_w);
            $(img).find('img').height(new_w / ratio_wh);
            $(desc).width(width_c - new_w);
        }
        else{
            $(img).width('100%').find('img').width('100%');
            $(img).find('img').height('auto');
            $(desc).innerWidth('100%');

            $(desc).each(function (item, el) {

                if( ($(el).next(img)).length > 0){
                    $(el).appendTo( $(img).closest('.product_item'));
                }
            });
        }
    })
}

$(window).on('load', function () {
    prodListImg();

    if($(window).width() > 768){
        $('#routes-map').height($('#contact_block').innerHeight());
    }
});
$(window).on('resize', function () {
    prodListImg();

    if($(window).width() > 768){
        $('#routes-map').height($('#contact_block').innerHeight());
    }
});

$(document).ready(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.slider').slick({
        dots: true,
        vertical: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        verticalSwiping: true,
    });

    $('.form-group.radio').on('change',function () {
        var changes = sumDataBigger();
        changePrice(changes);
    })

    $('.form-group.select .select2').each( function (i, el) {
        $(el).select2({
            placeholder: $(el).data('placeholder'),
            minimumResultsForSearch: -1,
            templateResult: formatText, //in dropdown list
            templateSelection: formatSelection
        }).on("select2:select", function (e) {
            var changes = sumDataBigger();
            changePrice(changes);
        });
    });

    $('.form-group.buy_block select.quantity').select2({
        placeholder: $(this).data('placeholder'),
        minimumResultsForSearch: -1
    }).on("select2:select", function (e) {

        var changes = sumDataBigger(),
            product_price = $('.product_price .base'),
            quantity = $('.form-group.buy_block select.quantity').find('option:selected').val(),
            float_price = parseFloat(quantity) * (parseFloat($(product_price).find('.float').attr('price'))),
            float_price_path = (((float_price%1).toFixed(2)).split("."))[1];
        console.log(changes);
        changePrice(changes);

    });

    $('#cart select.select2').select2({
        minimumResultsForSearch: -1
    });

    $('#formRoutes select.select2').select2({
        placeholder: "Country",
        minimumResultsForSearch: -1
    })


    var scrolling_down = true;
    // ajax load products
    function getProducts(page)
    {
        var link = $('input#pagination-link').val(),
            pageInput = $('input#page'),
            targetBlock = $('.product_list');

        $.get(link + '/' + page,{})
            .done(function (data) {
                targetBlock.append(data);
                pageInput.val(page);
                scrolling_down = true;

                prodListImg();
            })
            .fail(function () {
                console.log('Products could not be loaded.');
            });
    }

    // scrolling for ajax adding products in category
    $(window).scroll(function () {
        var pageInput = $('input#page'),
            totalPages = $('input#totaPages'),
            targetBlock = $('.product_list');

        if (totalPages.length && (parseInt(totalPages.val(), 10) !== 1) && targetBlock.length)
        {
            var last_page = parseInt( totalPages.val(), 10);
            var aTop = targetBlock.height();
            var page = parseInt(pageInput.val(), 10);

            if ($(window).scrollTop() >= (aTop - 800)) {
                if (scrolling_down === true) {
                    if (last_page != page) {
                        page += 1;
                        scrolling_down = false;
                        getProducts(page);
                    }
                }
            }
        }
    });

    //goofle map


    $(document).ready(function () {

        var styles = [{
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [{"visibility": "off"}, {"lightness": "-100"}, {"gamma": "7.29"}, {"weight": "0.01"}]
        }, {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [{"color": "#444444"}]
        }, {
            "featureType": "administrative.country",
            "elementType": "geometry.fill",
            "stylers": [{"visibility": "off"}, {"lightness": "20"}, {"gamma": "3.86"}, {"hue": "#ff0000"}, {"weight": "5.10"}]
        }, {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [{"visibility": "off"}, {"lightness": "72"}]
        }, {
            "featureType": "administrative.land_parcel",
            "elementType": "geometry.fill",
            "stylers": [{"visibility": "off"}, {"lightness": "-42"}]
        }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{"color": "#e9e9e9"}]
        }, {
            "featureType": "landscape.man_made",
            "elementType": "geometry",
            "stylers": [{"visibility": "off"}, {"lightness": "54"}, {"saturation": "69"}]
        }, {
            "featureType": "landscape.natural",
            "elementType": "geometry.fill",
            "stylers": [{"visibility": "on"}]
        }, {
            "featureType": "landscape.natural",
            "elementType": "geometry.stroke",
            "stylers": [{"visibility": "on"}]
        }, {
            "featureType": "landscape.natural",
            "elementType": "labels.text.fill",
            "stylers": [{"visibility": "off"}, {"color": "#ff0000"}, {"lightness": "-3"}]
        }, {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}]}, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{"saturation": -100}, {"lightness": 45}]
        }, {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {"color": "#9d9c96"}, {"visibility": "on"}
            ]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#d2d2d2"}, {"visibility": "on"}]
        }, {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [{"visibility": "off"}]
        }, {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{"visibility": "off"}]
        }, {"featureType": "water", "elementType": "all", "stylers": [{"color": "#a1a09b"}, {"visibility": "on"}]}];

        var markers = [];

        var route_markers = [];

        var directionsService = new google.maps.DirectionsService;
        var directionsDisplay = new google.maps.DirectionsRenderer;

        var map_block = $('#routes-map');

        var puurToevalAddress = $('#store_address').val();

        var iconBase = $('#store_img_src').val();

        function cleanFormRoutes() {
            $('#formRoutes input').each(function (item, el) {
                $(el).val('');
            })
            $('#formRoutes select').prop('selectedIndex',0);
            $('#formRoutes .select2').trigger('change');
            $('.puurToeval_wrapper').length > 0 ?  $('.puurToeval_wrapper').html('') : '';

        }

        function start_route_map() {

            directionsDisplay.setDirections({ routes: [] });

            cleanFormRoutes();
            delete $.ajaxSettings.headers['X-CSRF-TOKEN'];

            $.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + puurToevalAddress + '&key=AIzaSyBZq9Duw1o5SSwYPUfvoSmCEa-6bAMNUdA', function (response) {

                if (response.status == "OK") {

                    var location = response.results[0].geometry.location;
                    window.map = new google.maps.Map(document.getElementById($(map_block).attr('id')), {
                        center: location,
                        zoom: 10,
                        styles: styles,
                        disableDefaultUI: true
                    });
                    var icons = {
                        marker: {
                            icon: iconBase + 'Pin_only.png'
                        }
                    };

                    var features = [
                        {
                            position: new google.maps.LatLng(location['lat'],location['lng']),
                            type: 'marker'
                        }
                    ];



                    // Create markers
                    features.forEach(function(feature) {
                        var marker = new google.maps.Marker({
                            position: feature.position,
                            icon: icons[feature.type].icon,
                            map: map
                        });

                        markers.push(marker);

                    });

                    directionsDisplay.setMap(window.map);
                    directionsDisplay.setOptions({suppressMarkers: true});

                } else return;
            });

            $.ajaxSettings.headers['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content');

        }

        function start_map() {

            directionsDisplay.setDirections({ routes: [] });

            cleanFormRoutes();

            delete $.ajaxSettings.headers['X-CSRF-TOKEN'];

            $.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + puurToevalAddress + '&key=AIzaSyBZq9Duw1o5SSwYPUfvoSmCEa-6bAMNUdA', function (response) {

                if (response.status == "OK") {

                    var location = response.results[0].geometry.location;
                    window.map = new google.maps.Map(document.getElementById($(map_block).attr('id')), {
                        center: location,
                        zoom: 10,
                        styles: styles,
                        disableDefaultUI: true
                    });
                    var icons = {
                        marker: {
                            icon: iconBase + 'Pin.png'
                        }
                    };

                    var features = [
                        {
                            position: new google.maps.LatLng(location['lat'],location['lng']),
                            type: 'marker'
                        }
                    ];



                    // Create markers
                    features.forEach(function(feature) {
                        var marker = new google.maps.Marker({
                            position: feature.position,
                            icon: icons[feature.type].icon,
                            map: map,
                            label: {
                                text:   $(map_block).data('label'),
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "bold"
                            }
                        });

                        markers.push(marker);

                        marker.addListener('click', function() {

                            $('#formRoutes').fadeIn();
                            $('#contact_block').fadeOut();

                            $(map_block).height(function () {

                                var w_height = $(window).height(),
                                    h_height = $('header').innerHeight(),
                                    f_height = $('#formRoutes').innerHeight();

                                if( $(window).height() > (f_height +  h_height))
                                {
                                    return w_height - h_height;
                                }else{

                                    return f_height + 0.1 * w_height;
                                }


                            });

                            $(map_block).width(function () {
                                var w_width = $(window).width(),
                                    f_width = $('#formRoutes').innerWidth();

                                if (w_width > 768){
                                    return w_width + f_width;
                                }else{
                                    return w_width;
                                }

                            });

                            removeMarkers();

                            marker = new google.maps.Marker({
                                position: feature.position,
                                icon: iconBase + 'Pin_only.png',
                                map: map
                            });
                            markers.push(marker);
                        });
                    });

                    directionsDisplay.setMap(window.map);
                    directionsDisplay.setOptions({suppressMarkers: true});

                } else return;
            });

            $.ajaxSettings.headers['X-CSRF-TOKEN'] = $('meta[name="csrf-token"]').attr('content');

        }

        function formRoutesClear() {
            for (var i = 0; i < route_markers.length; i++) {
                route_markers[i].setMap(window.map);
            }

            $('.form-header.route').hide();
            $('.form-header.start').fadeIn();
        }

        function removeMarkers() {
            markers.forEach(function (el) {
                    el.setMap(null);
                }
            );
        }

        start_map();

        $('#find_route').on('click',function () {
            $('#formRoutes').fadeIn();
            $(this).css('opacity','0');
        })

        $('#formRoutes .clear').on('click', function () {
            formRoutesClear();
            start_route_map();
        });

        $('#formRoutes .close').on('click', function () {
            formRoutesClear();

            $('#formRoutes').fadeOut();
            $('#contact_block').fadeIn();
            $('.control_block').fadeOut();

            $(map_block).height($('#contact_block').height());

            start_map();

            $(map_block).width(function () {
                var w_width = $(window).width(),
                    c_width = $('#contacts').innerWidth(),
                    f_width = $('#formRoutes').innerWidth();

                if (w_width > 768){
                    return c_width/2;
                }else{
                    return c_width;
                }

            });
        });

        $('form[name="getLocation"]').on('submit', function (e) {
            e.preventDefault();
            var address = $(this.address).val();
            var city = $(this.city).val();
            var country = $(this.country).val()
            var sendData = address + ', ' + city + ' '+country,
                erorrText = $(this).data('textError');

            directionsService.route({
                origin: sendData,
                destination: puurToevalAddress,
                travelMode: google.maps.TravelMode.DRIVING
            }, function (response, status) {
                if (status === google.maps.DirectionsStatus.OK) {
                    $('.post-content').fadeOut();

                    $('.form-header.start').hide();
                    $('.form-header.route').fadeIn();
                    $('.control_block').fadeIn();

                    removeMarkers();

                    directionsDisplay.setDirections(response);
                    var leg = response.routes[0].legs[0];
                    var routeInfo = $('.puurToeval_wrapper');
                    $(routeInfo).html('').append('<div class="map-info"> <p>' + $(routeInfo).data('distance') + ': <span>'+leg.distance.value/1000+' km</span></p><p>' + $(routeInfo).data('duration') + ': <span>'+leg.duration.text+'</span></p></div>');

                    var route1 = new google.maps.Marker({
                        position: leg.start_location,
                        map: window.map,
                        icon: iconBase + 'route_me.png',
                        label: {
                            text: $(routeInfo).data('routeMe'),
                            color: "#000",
                            fontSize: "20px",
                            fontWeight: "bold"
                        }
                    });
                    route_markers.push(route1);
                    var route2 = new google.maps.Marker({
                        position: leg.end_location,
                        map: window.map,
                        icon: iconBase + 'store.png',
                    });
                    route_markers.push(route2);

                    if ($(window).width() < 768){
                        $('#formRoutes').fadeOut();
                        $('#find_route').css('opacity','1');
                    }
                } else {
                    var iconError = 'close-circle-outline',
                        typeError = 'danger';

                    pushNotifications.push(typeError, iconError, erorrText, 3000);
                    console.log(status);

                }
            });

        });
    });
});


$(document).ready(function() {
    $(document).on('click', '.delete-cart-item', function (e) {
        e.preventDefault();

        var el = $(this),
            url = el.data('url');

        loadCart(el, url, {
            quantity: 0,
            attributes: el.data('attributes')
        }, true, function (result) {

        });
    });

    $(document).on('submit', '.add-cart-form', function (e) {
        e.preventDefault();

        var el = $(this),
            url = el.attr('action'),
            data = el.serialize(),
            text = el.data('success');

        loadCart(el, url, data, false, function (result) {});
    });

    $(document).on('change', '.cart-quantity', function (e) {
        e.preventDefault();

        var el = $(this),
            url = el.data('url'),
            text = el.data('success'),
            previous = el.attr('previous');

        loadCart(el, url, {
            quantity: el.val(),
            attributes: el.data('attributes'),
            montage: el.data('montage')
        }, false, function (result) {
            if (result)
            {
                el.attr('previous', el.val());
            }else{
                el.val(previous).trigger('change.select2');
            }
        });
    });

    // load cart html and add or delete product from cart
    function loadCart(el, url, data, deleteItem, callback) {
        var totalCountBlock = $('.cart-total-number'),
            totalPriceBlock = $('.cart-total-price'),
            totalPriceVatBlock = $('.cart-total-vat');

        var typeSuccess = 'success',
            typeError = 'danger',
            iconError = 'close-circle-outline',
            iconSuccess = 'customer_success';

        $.post(url, data).done(function (response) {
            if(response.action_status === 'OK') {
                if(response.result_added) {

                    pushNotifications.push(typeSuccess, iconSuccess, response.successText, 3000);

                    totalPriceBlock.text(response.totalCost);
                    totalCountBlock.text(response.total);
                    totalPriceVatBlock.text(response.vat);

                    if(deleteItem){
                        if(response.action === 'reload') {
                            window.location = response.reload_link;
                        }else {
                            $(el.data('item')).remove();
                        }
                    }else {
                        el.closest('.cart-item').find('.total-product-price-s').text(response.productPrice);
                    }
                }else{
                    pushNotifications.push(typeError, iconError, response.errorText, 3000);
                }

                callback(response.result_added, response);
            }
        }).fail(function () {
            callback(false);
        });
    }

    $(document).on('click', '.show-order', function (e) {
        e.preventDefault();

        var orderForm = $('#personal_data'),
            cartFrom = $('#cart');

        orderForm.show();
        cartFrom.find('.cart-item .product-del div').hide();
        cartFrom.find('.cart-item .cart-quantity').attr('disabled', 'disabled').trigger('change.select2');

        $('html, body').animate({
            scrollTop: orderForm.offset().top
        }, 1000);
    });

    var canSubmitOrder = true;

    $(document).on('submit', '#order-form', function (e) {
        e.preventDefault();

        var el = $(this),
            orderForm = $('#personal_data'),
            cartFrom = $('#cart');

        if (canSubmitOrder) {

            canSubmitOrder = false;
            el.find('.order-submit-btn').addClass('disabled').attr('disabled', 'disabled');

            $.post(el.attr('action'), el.serialize())
                .done(function (response) {
                    if (response.success === 'OK') {
                        orderForm.remove();
                        cartFrom.empty().html(response.data);
                        $('html, body').animate({scrollTop: 0}, 1000);
                        $('.cart-total-number').text(0);

                    } else if (response.success === 'ERROR' && typeof response.messageError !== 'undefined') {
                        alert(response.messageError);
                    }
                }).fail(function (data) {

                if (data.status === 422) {

                    var errors = data.responseJSON.errors;

                    el.find('.form-control').removeClass('error');
                    el.find('.error-massage').text('');
                    $.each(errors, function (key, value) {

                        el.find("[name='" + key + "']").addClass('error');
                        el.find("[name='" + key + "']").parent().find('.error-massage').text(value.toString());
                    });

                    canSubmitOrder = true;
                    el.find('.order-submit-btn').removeClass('disabled').removeAttr('disabled');
                }

            });
        }
    });

    $('#order-form').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    var canSubmitContact = true;

    $(document).on('submit', '.contact-form, .newsletter-form', function (e) {
        e.preventDefault();

        var el = $(this);
        var typeSuccess = 'success',
            iconSuccess = 'check-circle-outline';

        if (canSubmitContact) {

            canSubmitContact = false;
            el.find('.contact-submit-btn').addClass('disabled').attr('disabled', 'disabled');

            $.post(el.attr('action'), el.serialize())
                .done(function (response) {
                    if (response.success === 'OK') {
                        el.remove();

                        pushNotifications.push(typeSuccess, iconSuccess, response.successText, 3000);

                    } else if (response.success === 'ERROR' && typeof response.messageError !== 'undefined') {
                        alert(response.messageError);
                    }
                }).fail(function (data) {

                if (data.status === 422) {

                    var errors = data.responseJSON.errors;

                    el.find('.error-massage').text('');
                    $.each(errors, function (key, value) {

                        el.find("[name='" + key + "']").parent().find('.error-massage').text(value.toString());
                    });

                    canSubmitContact = true;
                    el.find('.contact-submit-btn').removeClass('disabled').removeAttr('disabled');
                }

            });
        }
    });

    $(document).on('click', '.show-subcat', function (e) {
        e.preventDefault();

        var showIt = false;

        if($('[data-item="' + $(this).data('target') + '"]').is(':hidden'))
            showIt = true;

        $('.subcategory-item').hide();

        if(showIt)
            $('[data-item="' + $(this).data('target') + '"]').fadeIn();
    });
});

window.pushNotifications = new function ($node) {
    $node = $('[push-notifications]');

    var self = this;
    var notes = $node.find('.notification');
    var template = $node.find('.template');
    var shownNotes = [];
    var maxCount = 4;

    self.bind = function ($note) {
        if (!$note.hasClass('shown')) {
            $note.addClass('shown');
        }

        var note = {
            note: $note
        };

        note.timeout = setTimeout(function () {
            self.deleteNotification(note);
        }, parseInt(note.note.attr('expire')) * 1);

        shownNotes.push(note);

        note.note.find('.close-notification').unbind('click').bind('click', function () {
            self.deleteNotification(note);
        });

        if (shownNotes.length > maxCount) {
            var _note = shownNotes[0];

            self.deleteNotification(_note);
        }
    };

    self.deleteNotification = function (note) {
        clearTimeout(note.timeout);
        note.note.removeClass('shown');
        shownNotes.splice(shownNotes.indexOf(note), 1);

        setTimeout(function () {
            note.note.remove();
        }, 450);
    };

    self.init = function () {
        notes.each(function (index, note) {
            self.bind($(note));
        });
    };

    self.push = function (type, icon, text, timeout) {
        var notification = template.clone();

        notification.removeClass('template');
        notification.addClass('notification');
        notification.addClass('notification-' + type);

        notification.removeAttr('hidden');
        notification.attr('expire', timeout);
        notification.find('.text').text(text);
        notification.find('.icon').addClass('mdi mdi-' + icon);

        $node.find('.inner').prepend(notification);

        setTimeout(function () {
            self.bind(notification);
        }, 100);
    };

    self.init();
}();